import React, { useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../services/firebase';

const CreateBoardModal = ({ open, onClose }) => {
    const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = async () => {
    await addDoc(collection(db, 'boards'), { title, description });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Create Board</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Board Name</label>
            <input 
              placeholder='Board Name' 
              value={title} 
              onChange={(e) => setTitle(e.target.value)} 
            />
          </Form.Field>

            <Form.Field>
            <label>Board Description</label>
            <input 
              placeholder='Board Description' 
              value={description} 
              onChange={(e) => setDescription(e.target.value)}
            />
            </Form.Field>
          <Button type='submit' primary>Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default CreateBoardModal;
