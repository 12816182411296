import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { Dimmer, Loader, Image } from 'semantic-ui-react';

const AsyncImage = ({ path, style }) => {
  const storage = getStorage();
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const url = await getDownloadURL(ref(storage, path));
        setImageUrl(url);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [path]);

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader size='large'>Loading</Loader>
      </Dimmer>
    );
  }

  if (error) {
    return null;
  }

  return (
    <Image src={imageUrl} style={style} />
  );
};

export default AsyncImage;
