import React from 'react';
import { Card, Image } from 'semantic-ui-react';

const AsyncImage = ({ src, ...props }) => <Image src={src} {...props} />;

export default function SearchCardCell({ card }) {
  const getBackgroundColor = (cardType) => {
    switch (cardType) {
      case 'pronoun':
        return '#FFCDD2';
      case 'noun':
        return '#C8E6C9';
      case 'verb':
        return '#BBDEFB';
      case 'adjective':
        return '#FFECB3';
      case 'adverb':
        return '#E1BEE7';
      case 'preposition':
        return '#B2EBF2';
      default:
        return '#f8f8f8';
    }
  };

  return (
    <Card style={{ backgroundColor: getBackgroundColor(card.cardType), margin: '10px' }}>
      <Card.Content>
        <Image floated="left" size="mini" src={`cards/${card.id}/image.png`} style={{ borderRadius: '50%' }} />
        <Card.Header>{card.title}</Card.Header>
        {card.detail && <Card.Description>{card.detail}</Card.Description>}
      </Card.Content>
    </Card>
  );
}
