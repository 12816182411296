import React, { useState } from 'react';
import { Button, Form, Container, Header, Message } from 'semantic-ui-react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const Login = () => {
    const auth = getAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container>
      <Header as="h2">Login</Header>
      <Form onSubmit={handleLogin}>
        <Form.Field>
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Field>
        <Button type="submit" primary>Login</Button>
      </Form>
      {error && <Message error>{error}</Message>}
    </Container>
  );
};

export default Login;
