import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { db } from '../services/firebase';

const CreateCardModal = ({ open, onClose, onSave }) => {
    const [title, setTitle] = useState('');
    const [cardType, setCardType] = useState('other');
    const [cardCategory, setCardCategory] = useState('');

    const handleSubmit = async () => {
        await addDoc(collection(db, 'cards'), { title, cardType, cardCategory});
        onClose();
    };

  return (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>Create Card</Modal.Header>
        <Modal.Content>
        <Form onSubmit={handleSubmit}>
        <Form.Field>
            <label>Card Title</label>
            <input 
                placeholder='Title' 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
            />
        </Form.Field>

        <Form.Select
            label='Card Type'
            options={[
                { key: 'pronoun', text: 'Pronoun', value: 'pronoun' },
                { key: 'noun', text: 'Noun', value: 'noun' },
                { key: 'verb', text: 'Verb', value: 'verb' },
                { key: 'adjective', text: 'Adjective', value: 'adjective' },
                { key: 'adverb', text: 'Adverb', value: 'adverb' },
                { key: 'preposition', text: 'Preposition', value: 'preposition' },
                { key: 'other', text: 'Other', value: 'other' },
            ]}
            placeholder='Card Type'
            value={cardType}
            onChange={(_, data) => setCardType(data.value)}
        />

        <Form.Select
            label='Card Category'
            options={[
                { key: 'clothes', text: 'Clothes', value: 'clothes' },
                { key: 'colors', text: 'Colors', value: 'colors' },
                { key: 'food', text: 'Food', value: 'food' },
                { key: 'animals', text: 'Animals', value: 'animals' },
                { key: 'body', text: 'Body', value: 'body' },
                { key: 'emotions', text: 'Emotions', value: 'emotions' },
                { key: 'family', text: 'Family', value: 'family' },
                { key: 'numbers', text: 'Numbers', value: 'numbers' },
                { key: 'objects', text: 'Objects', value: 'objects' },
                { key: 'places', text: 'Places', value: 'places' },
                { key: 'seasons', text: 'Seasons', value: 'seasons' },
                { key: 'weather', text: 'Weather', value: 'weather' },
                { key: 'other', text: 'Other', value: 'other' },
            ]}
            placeholder='Card Category'
            value={cardCategory}
            onChange={(_, data) => setCardCategory(data.value)}
        />
        
          <Button type='submit' primary>Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default CreateCardModal;
