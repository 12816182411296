import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Header, Input, Modal, Card } from 'semantic-ui-react';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, functions } from '../services/firebase';
import GridItem from '../models/Card';
import { getAuth } from 'firebase/auth';
import GridCell from '../components/GridCell';
import SearchCards from './SearchCards';
import { httpsCallable } from 'firebase/functions';
import AsyncImage from './AsyncImage';

const BoardDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [grid, setGrid] = useState([[{ title: 'Tap Here' }]]);
    const [boardTitle, setBoardTitle] = useState('New Board');
    const [boardSubtitle, setBoardSubtitle] = useState('');
    const [boardDescription, setBoardDescription] = useState('');
    const [open, setOpen] = useState(false);
    const [numberOfColumns, setNumberOfColumns] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCell, setSelectedCell] = useState({ row: null, column: null });
    const [refreshing, setRefreshing] = useState(false);

    useEffect(() => {
        getItems();
        getBoard();
    }, []);

    useLayoutEffect(() => {
        document.title = boardTitle;
    }, [boardTitle]);
    
    const getBoard = async () => {
        const boardSnapshot = await getDoc(doc(db, 'boards', id));
        setBoardTitle(boardSnapshot.data().title);
        setBoardDescription(boardSnapshot.data().description);
    };

    const getItems = async () => {
        let itemsSnapshot = await getDocs(collection(db, 'boards', id, 'cards'));
        const itemsList = itemsSnapshot.docs.map(doc => new GridItem(doc.id, doc.data()));
        const numRows = itemsList.reduce((max, item) => Math.max(max, item.row), 0) + 1;
        const numCols = itemsList.reduce((max, item) => Math.max(max, item.column), 0) + 1;
        const grid = Array.from({ length: numRows }, () => Array(numCols).fill(null));
        itemsList.forEach(item => {
            const { row, column } = item;
            if (row < numRows && column < numCols) {
                grid[row][column] = new GridItem(item.id, { ...item });
            }
        });
        setNumberOfColumns(numCols);
        setGrid(grid);
    };

    const addColumn = () => {
        setGrid(prevGrid => prevGrid.map(row => [...row, { title: '' }]));
    };

    const addRow = () => {
        setGrid(prevGrid => [...prevGrid, new Array(prevGrid[0]?.length ?? 1).fill({ title: '' })]);
    };

    const onSave = async () => {
        await updateDoc(doc(db, 'boards', id), {
            title: boardTitle,
        });

        let data = grid.map((row, rowIndex) => {
            return row.map((cell, colIndex) => {
                return {
                    title: cell.title,
                    cardID: cell.cardID,
                };
            });
        });
        data.forEach((row, rowIndex) => {
            row.forEach(async (cell, colIndex) => {
                await setDoc(doc(db, 'boards', id, 'cards', `${rowIndex}-${colIndex}`), {
                    column: colIndex,
                    row: rowIndex,
                    ...cell
                });
            });
        });
        navigate(-1);
    };

    const onRefresh = async () => {
        setRefreshing(true);
        try {
            const refreshCardIcon = httpsCallable(functions, 'refreshBoardIcon');
            await refreshCardIcon({ boardId: id });
        } catch (error) {
            console.error(error);
        }
        setRefreshing(false);
    };

    const updateContentForCell = (content, row, column) => {
        setGrid(prevGrid => prevGrid.map((gridRow, rowIndex) => {
            if (rowIndex === row) {
                return gridRow.map((cell, colIndex) => {
                    if (colIndex === column) {
                        return {
                            ...cell,
                            ...content
                        };
                    }
                    return cell;
                });
            }
            return gridRow;
        }));
    };

    const showEditTitleAlert = () => {
        setOpen(true);
    };

    const handleSaveTitle = () => {
        saveTitle(boardTitle, boardSubtitle, boardDescription);
        setOpen(false);
    };

    const saveTitle = async (newTitle, newSubtitle, newDescription) => {
        if (id) {
            await updateDoc(doc(db, 'boards', id), {
                title: newTitle,
                subtitle: newSubtitle,
                description: newDescription
            });
        }
    };

    const handleCellClick = (row, column) => {
        setSelectedCell({ row, column });
        setModalOpen(true);
    };

    const handleCardSelect = (content) => {
        const { row, column } = selectedCell;
        updateContentForCell(content, row, column);
        setModalOpen(false);
    };

    const handleDeleteBoard = async () => {
        await deleteDoc(doc(db, 'boards', id));
        navigate('/boards');
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={2}>
                    <AsyncImage path={`boards/${id}/banner.png`} style={{ width: 100 }} />
                </Grid.Column>
                <Grid.Column width={6}>
                    <Header as='h1' onClick={showEditTitleAlert} content={boardTitle} subheader={boardSubtitle} />
                    <p>{boardDescription}</p>
                </Grid.Column>
                <Grid.Column width={8} textAlign='right'>
                    <Button primary onClick={onSave}>Save</Button>
                    <Button primary loading={refreshing} onClick={onRefresh}>Refresh</Button>
                    <Button color='red' onClick={() => setDeleteModalOpen(true)}>Delete</Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid>
                    {grid.map((row, rowIndex) => (
                        <Grid.Row key={rowIndex}>
                            <Card.Group itemsPerRow={numberOfColumns}>
                                {row.map((cell, colIndex) => (
                                    <GridCell
                                        key={`${rowIndex}-${colIndex}`}
                                        cell={cell}
                                        row={rowIndex}
                                        column={colIndex}
                                        onClick={() => handleCellClick(rowIndex, colIndex)}
                                    />
                                ))}
                            </Card.Group>
                        </Grid.Row>
                    ))}
                </Grid>
            </Grid.Row>
            <Grid.Row>
                <div style={{ marginTop: '20px' }}>
                    <Button onClick={addRow}>Add Row</Button>
                    <Button onClick={addColumn}>Add Column</Button>
                </div>
            </Grid.Row>

            <SearchCards open={modalOpen} onClose={() => setModalOpen(false)} setContent={handleCardSelect} />

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                size='small'
            >
                <Header icon='edit' content='Edit Board Title' />
                <Modal.Content>
                    <Input
                        fluid
                        placeholder='New Board Title'
                        value={boardTitle}
                        onChange={(e) => setBoardTitle(e.target.value)}
                    />
                    <Input
                        fluid
                        placeholder='Subtitle'
                        value={boardSubtitle}
                        onChange={(e) => setBoardSubtitle(e.target.value)}
                    />
                    <Input
                        fluid
                        placeholder='Description'
                        value={boardDescription}
                        onChange={(e) => setBoardDescription(e.target.value)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button color='green' onClick={handleSaveTitle}>
                        Save
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                size='small'
            >
                <Header icon='trash' content='Delete Board' />
                <Modal.Content>
                    <p>Are you sure you want to delete this board?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setDeleteModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button color='green' onClick={handleDeleteBoard}>
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    );
};

export default BoardDetail;
