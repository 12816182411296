class Card {
    constructor(id, data) {
        this.id = id;
        this.cardID = data.cardID;
        this.cardType = data.cardType;
        this.cardCategory = data.cardCategory;
        this.title = data.title;
        this.column = data.column
        this.row = data.row
    }
}
export default Card;
  