import React, { useEffect, useState } from 'react';
import { Button, Card, Grid, Header } from 'semantic-ui-react';
import { db } from '../services/firebase';
import CreateCardModal from './CreateCardModal';
import {collection, getDocs} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { CARDS_PATH } from '../constants/paths';
import GridCell from './GridCell';

const CardComponent = () => {
    const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchCards = async () => {
        const cardCollection = collection(db, 'cards');
        const cardSnapshot = await getDocs(cardCollection);
        setCards(cardSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchCards();
  }, []);

  return (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column>
                <Header as='h2'>Cards</Header>
            </Grid.Column>
            <Grid.Column textAlign='right'>
            <Button primary onClick={() => setModalOpen(true)}>Create Card</Button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
            <Grid.Column>
                <Card.Group>
                    {cards.map((card) => <GridCell key={card.id} cell={card} onClick={() => navigate(`${CARDS_PATH}/${card.id}`)} />
                    )}
                </Card.Group>
            </Grid.Column>
        </Grid.Row>
        <CreateCardModal
            open={isModalOpen}
            onClose={() => setModalOpen(false)}
        />
    </Grid>
  );
};

export default CardComponent;
