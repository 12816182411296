// GridCell.js
import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import AsyncImage from './AsyncImage';

const GridCell = ({ cell, onClick }) => {

  const getBorderColor = (cardType) => {
    switch (cardType) {
      case 'pronoun':
        return '#FFCDD2';
      case 'noun':
        return '#C8E6C9';
      case 'verb':
        return '#BBDEFB';
      case 'adjective':
        return '#FFECB3';
      case 'adverb':
        return '#E1BEE7';
      case 'preposition':
        return '#B2EBF2';
      default:
        return 'black';
    }
  };

  const getBackgroundColor = (cardType) => {
    // make a little lighter than the border color
    switch (cardType) {
      case 'pronoun':
        return '#FFEBEE';
      case 'noun':
        return '#E8F5E9';
      case 'verb':
        return '#E3F2FD';
      case 'adjective':
        return '#FFFDE7';
      case 'adverb':
        return '#F3E5F5';
      case 'preposition':
        return '#B3E5FC';
      default:
        return '#f8f8f8';
    }
  };

  const id = cell?.cardID ?? cell?.id;

  return (
    <Card
      onClick={onClick}
      style={{
        borderColor: getBorderColor(cell?.cardType),
        backgroundColor: getBackgroundColor(cell?.cardType),
        borderWidth: '2px',
        width: '100px',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {id && (
        <AsyncImage path={`cards/${id}/icon.png`} style={{ width: '50px', height: '50px' }} />
      )}
      <Card.Content>
        <Card.Header>{cell?.title ?? "Tap Here"}</Card.Header>
      </Card.Content>
    </Card>
  );
};

export default GridCell;
