import React, { useState } from 'react';
import { Modal, Button, Icon, Input, Form, Dropdown } from 'semantic-ui-react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, SearchBox } from 'react-instantsearch-dom';
import SearchCardCell from '../components/SearchCardCell';
import Card from '../models/Card';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../services/firebase';

const searchClient = algoliasearch('K4OTQQTNP2', '29311a4ad57d15013095ef36ecfe4d56');

const cardTypes = [
    { key: 'adjective', text: 'Adjective', value: 'adjective' },
    { key: 'verb', text: 'Verb', value: 'verb' },
    { key: 'noun', text: 'Noun', value: 'noun' },
    { key: 'adverb', text: 'Adverb', value: 'adverb' },
    { key: 'pronoun', text: 'Pronoun', value: 'pronoun' },
    { key: 'preposition', text: 'Preposition', value: 'preposition' },
    { key: 'conjunction', text: 'Conjunction', value: 'conjunction' },
    { key: 'interjection', text: 'Interjection', value: 'interjection' },
    { key: 'other', text: 'Other', value: 'other' },
];

const cardCategories = [
    { key: 'animals', text: 'Animals', value: 'animals' },
    { key: 'foods', text: 'Foods', value: 'foods' },
    { key: 'drinks', text: 'Drinks', value: 'drinks' },
    { key: 'actions', text: 'Actions', value: 'actions' },
    { key: 'colors', text: 'Colors', value: 'colors' },
    { key: 'emotions', text: 'Emotions', value: 'emotions' },
    { key: 'objects', text: 'Objects', value: 'objects' },
    { key: 'people', text: 'People', value: 'people' },
    { key: 'places', text: 'Places', value: 'places' },
    { key: 'body_parts', text: 'Body Parts', value: 'body_parts' },
    { key: 'clothing', text: 'Clothing', value: 'clothing' },
    { key: 'vehicles', text: 'Vehicles', value: 'vehicles' },
    { key: 'numbers', text: 'Numbers', value: 'numbers' },
    { key: 'letters', text: 'Letters', value: 'letters' },
    { key: 'weather', text: 'Weather', value: 'weather' },
    { key: 'shapes', text: 'Shapes', value: 'shapes' },
    { key: 'times', text: 'Times', value: 'times' },
    { key: 'days', text: 'Days', value: 'days' },
    { key: 'months', text: 'Months', value: 'months' },
    { key: 'transportation', text: 'Transportation', value: 'transportation' },
    { key: 'directions', text: 'Directions', value: 'directions' },
    { key: 'questions', text: 'Questions', value: 'questions' },
    { key: 'responses', text: 'Responses', value: 'responses' },
    { key: 'furniture', text: 'Furniture', value: 'furniture' },
    { key: 'nature', text: 'Nature', value: 'nature' },
    { key: 'other', text: 'Other', value: 'other' },
  ]

function Hit({ hit, handleItemPress }) {
    return (
        <div onClick={() => handleItemPress(hit)}>
            <SearchCardCell card={new Card(hit.objectID, hit)} />
        </div>
    );
}

function SearchCards({ open, onClose, setContent }) {
    const [isCreatingNewCard, setIsCreatingNewCard] = useState(false);
    const [newCardTitle, setNewCardTitle] = useState('');
    const [newCardType, setNewCardType] = useState('noun');
    const [newCardCategory, setNewCardCategory] = useState('other');

    const handleCreateNewCard = () => {
        setIsCreatingNewCard(true);
    };

    const handleSaveNewCard = async () => {

        const doc = await addDoc(collection(db, 'cards'), {
            title: newCardTitle,
            cardType: newCardType,
            cardCategory: newCardCategory,
        });

        console.log('Document written with ID: ', {
            cardID: doc.id,
            title: newCardTitle,
            cardType: newCardType,
            cardCategory: newCardCategory,
        });

        setContent({
            cardID: doc.id,
            title: newCardTitle,
            cardType: newCardType,
            cardCategory: newCardCategory,
        });
        setIsCreatingNewCard(false);
        onClose();
    };

    const handleItemPress = (item) => {
        setContent({
            title: item.title,
            cardID: item.objectID,
            cardType: item.cardType,
            cardCategory: item.cardCategory,
        });
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose} size="large">
            <Modal.Header>{isCreatingNewCard ? 'Create New Card' : 'Search Cards'}</Modal.Header>
            <Modal.Content>
                {isCreatingNewCard ? (
                    <Form>
                        <Form.Field>
                            <label>Title</label>
                            <Input
                                placeholder='Enter title'
                                value={newCardTitle}
                                onChange={(e) => setNewCardTitle(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Card Type</label>
                            <Dropdown
                                placeholder='Select Card Type'
                                fluid
                                selection
                                options={cardTypes}
                                value={newCardType}
                                onChange={(e, { value }) => setNewCardType(value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Card Category</label>
                            <Dropdown
                                placeholder='Select Card Category'
                                fluid
                                selection
                                options={cardCategories}
                                value={newCardCategory}
                                onChange={(e, { value }) => setNewCardCategory(value)}
                            />
                        </Form.Field>
                    </Form>
                ) : (
                    <InstantSearch searchClient={searchClient} indexName="cards">
                        <div style={{ marginBottom: '1em' }}>
                            <SearchBox />
                        </div>
                        <Hits hitComponent={({ hit }) => <Hit hit={hit} handleItemPress={handleItemPress} />} />
                    </InstantSearch>
                )}
            </Modal.Content>
            <Modal.Actions>
                {isCreatingNewCard ? (
                    <>
                        <Button color="green" onClick={handleSaveNewCard}>
                            <Icon name="check" /> Save
                        </Button>
                        <Button onClick={() => setIsCreatingNewCard(false)}>
                            <Icon name="close" /> Cancel
                        </Button>
                    </>
                ) : (
                    <>
                        <Button color="green" onClick={handleCreateNewCard}>
                            <Icon name="plus" /> Create New Card
                        </Button>
                        <Button onClick={onClose}>
                            <Icon name="close" /> Close
                        </Button>
                    </>
                )}
            </Modal.Actions>
        </Modal>
    );
}

export default SearchCards;
