// BoardCell.js
import React from 'react';
import { Card } from 'semantic-ui-react';
import AsyncImage from './AsyncImage';

const BoardCell = ({ board, onClick }) => {
  const { id, title } = board;
  return (
    <Card
      onClick={onClick}
    >
      {id && (
        <AsyncImage path={`boards/${id}/banner.png`} style={{ width: '100%'}} />
      )}
      <Card.Content>
        <Card.Header>{title}</Card.Header>
      </Card.Content>
    </Card>
  );
};

export default BoardCell;
