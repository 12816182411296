import React, { useState, useEffect, useContext } from 'react';
import { Loader, Menu } from 'semantic-ui-react';
import Board from './components/Board';
import CardComponent from './components/CardComponent';
import Login from './components/Login';
import { BrowserRouter, Routes, Route, Link, NavLink, Navigate } from "react-router-dom";
import { BOARDS_PATH, CARDS_PATH, HOME_PATH, LOGIN_PATH } from './constants/paths';
import BoardDetail from './components/BoardDetail';
import CardDetail from './components/CardDetail';
import { AuthContext } from './providers/AuthProvider';

export const AuthenticatedRoute = ({ element }) => {
  const { user, loading } = useContext(AuthContext);
  if (loading) return <Loader active />;
  if (!user) return <Navigate to={LOGIN_PATH} replace />;
  return element;
};

export const UnauthenticatedRoute = ({ element }) => {
  const { user, loading } = useContext(AuthContext);
  if (loading) return <Loader active />;
  if (user) return <Navigate to={HOME_PATH} replace />;
  return element;
};


const App = () => {

  return (
    <BrowserRouter>
      <Menu>
        <Menu.Item
          name='boards'
          to="/"
          as={NavLink}
        >
          Boards
        </Menu.Item>
        <Menu.Item
          name='cards'
          to={CARDS_PATH}
          as={NavLink}
        >
          Cards
        </Menu.Item>
      </Menu>
      <Routes>
        <Route path={HOME_PATH} element={<AuthenticatedRoute element={<Board />} />} />
        <Route path={BOARDS_PATH}>
          <Route
            index={true}
            element={<AuthenticatedRoute element={<Board />} />}
          />
          <Route
            path={`:id`}
            element={<AuthenticatedRoute element={<BoardDetail />} />}
          />
        </Route>
        <Route path={CARDS_PATH}>
          <Route
            index={true}
            element={<AuthenticatedRoute element={<CardComponent />} />}
          />
          <Route
            path={`:id`}
            element={<AuthenticatedRoute element={<CardDetail />} />}
          />
        </Route>
        <Route path={LOGIN_PATH} element={<UnauthenticatedRoute element={<Login />} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
