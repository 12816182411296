import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Grid, Header } from 'semantic-ui-react';
import { db } from '../services/firebase';
import {collection, getDocs} from 'firebase/firestore';
import CreateBoardModal from './CreateBoardModal';
import { useNavigate } from 'react-router-dom';
import { BOARDS_PATH } from '../constants/paths';
import BoardCell from './BoardCell';

const Board = () => {
    const navigate = useNavigate();
  const [boards, setBoards] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchBoards = async () => {
        const boardCollection = collection(db, 'boards');
        const boardSnapshot = await getDocs(boardCollection);
        setBoards(boardSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };
    fetchBoards();
  }, []);

  return (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column>
                <Header as='h2'>Boards</Header>
            </Grid.Column>
            <Grid.Column textAlign='right'>
                <Button primary onClick={() => setModalOpen(true)}>Create Board</Button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
            <Grid.Column>
                <Card.Group itemsPerRow={4} stackable>
                    {boards.map((board) => <BoardCell key={board.id} board={board} onClick={() => navigate(`${BOARDS_PATH}/${board.id}`)} />)}
                </Card.Group>
            </Grid.Column>
        </Grid.Row>
        <CreateBoardModal
            open={isModalOpen}
            onClose={() => setModalOpen(false)}
        />
    </Grid>
  );
};

export default Board;
