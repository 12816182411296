import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Header, Input, Modal, Card } from 'semantic-ui-react';
import { addDoc, collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { db, functions } from '../services/firebase';
import { getAuth } from 'firebase/auth';
import { httpsCallable } from "firebase/functions";
import AsyncImage from './AsyncImage';

const CardDetail = () => {
    const { id } = useParams();
    const [card, setCard] = useState(null);
    const [isRefreshing, setRefreshing] = useState(false);

    useEffect(() => {
        getCard();
    }, []);
    
    const getCard = async () => {
        const cardSnapshot = await getDoc(doc(db, 'cards', id));
        setCard(cardSnapshot.data());
    };

    const onRefresh = async () => {
        setRefreshing(true);
        try {
        const refreshCardIcon = httpsCallable(functions, 'refreshCardIcon');
        await refreshCardIcon({ cardId: id })
        } catch (error) {
        console.error(error);
        }
        setRefreshing(false);
    };

    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Header as='h1'>{card?.title ?? "Unknown"}</Header>
                    <AsyncImage path={`cards/${id}/icon.png`} />
                </Grid.Column>
                <Grid.Column>
                    <Button primary loading={isRefreshing} onClick={onRefresh}>Refresh</Button>
                </Grid.Column>
                </Grid.Row>
        </Grid>
    );
};

export default CardDetail;
